html,
body,
#root {
  height: 100%;
}

p {
  font-size: 1.1em;
}

.cookieBanner {
  background: rgb(53, 53, 53) none repeat scroll 0% 0%;
  color: white;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  left: 0px;
  position: fixed;
  width: 100%;
  z-index: 999;
  bottom: 0px;
  padding: 1em;
}

.pageTitle {
  color: var(--bs-primary);
  user-select: none;
  font-size: 4rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

@media (max-width: 544px) {
  .pageTitle {
    font-size: 2.5rem;
  }
}

.App {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  min-height: 100%;
  padding-top: 3rem;
  background: white url("./images/background4.png") center;
  padding-bottom: 3rem;
}

a {
  color: initial !important;
  text-decoration: none !important;
}

a:hover {
  color: initial;
  text-decoration: none;
}

.specialHeading {
  color: #e8e8e8;
  user-select: none;
  filter: drop-shadow(1px 1px 2px #000);
}

@media (max-width: 544px) {
  .specialHeading {
    font-size: 1.2rem;
  }
}

/* HOVERABLE SERIES/TECHNIQUE FIELDS */

.outer {
  height: 100%;
  display: grid;
  grid-template: 1fr / 1fr;
  place-items: center;
  overflow: hidden;
}

.outer > * {
  grid-column: 1 / 1;
  height: 100%;
  width: 100%;
  grid-row: 1 / 1;
}

.outer .below {
  z-index: 1;
  filter: brightness(70%);
  transform: scale(1);
  transition: all 0.3s ease;
}

.outer .belowSpecial {
  filter: brightness(50%);
}
.outer:hover .belowSpecial {
  filter: brightness(100%);
}

.outer .top {
  z-index: 2;
}

.outer:hover .below {
  filter: brightness(100%);
  transform: scale(1.3);
}

/* PAINTING SHADOW */

.paintingShadow {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

.blueLink {
  color: var(--bs-secondary) !important;
}

.loadingButton svg {
  display: none;
}

.loadingButton span {
  display: inline-block;
}

.idleButton svg {
  display: initial;
}

.idleButton span {
  display: none;
}

.spinnerPrimary {
  color: var(--bs-primary);
}

.analyticsButtonDecline {
  background-color: transparent !important;
  color: white !important;
  text-decoration: underline !important;
  text-decoration-color: white !important;
}

.analyticsButtonAccept {
  background-color: var(--bs-success) !important;
  color: white !important;
}


#scrollToTopButton {
  transition: visibility 0s, opacity 0.5s linear;
  visibility: hidden;
  opacity: 0;
  background-color: var(--bs-primary);
  color: white;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19) !important;
}

#scrollToTopButton:focus {
  border: none !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19) !important;
}