.techniqueGrid {
  display: grid;
  grid-template-columns: repeat(6, 0.6fr);
  grid-template-rows: 1.5fr 0.5fr;
  gap: 5px 5px;
}

.specialTechnique {
  grid-column: 1 / -1;
}

.special {
  display: flex;
  justify-content: center;
  align-items: center;
}

.techniqueHeading {
  color: white;
  user-select: none;
  background: -webkit-linear-gradient(#eee, #666);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.vertical {
  writing-mode: vertical-rl;
  padding: 0;
  margin: 0.4em;
}

.rail {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.rail:hover .railBackground {
  filter: brightness(100%);
  transform: scale(1.3);
}

.railBackground {
  background-color: #0a53be;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  filter: brightness(70%);
  transform: scale(1);
  transition: all 0.3s ease;
}

.railForeground {
  position: absolute;
  height: 100%;
  z-index: 2;
}

.specialTest {
  grid-column: 1 / -1;
  width: 100%;
  height: 100%;
  background-color: #0f5132;
}