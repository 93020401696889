.paintingGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 18rem);
  align-items: center;
  justify-content: center;
  column-gap: 75px;
  row-gap: 75px;
}

.paintingGrid > div, .paintingGrid img {
  width: 18rem;
}

.paintingGrid .paintingImageWrapper {
  min-height: 30vh;
}

.paintingImageWrapper {
  display: flex;
  align-items: flex-end;
}