.navigator a {
  display: block;
  border: 2px solid transparent;
  font-size: 1.2em;
  padding: 0.5em;
  color: #ba0000 !important;
}

.navigator a:hover {
  border: 2px solid #ba0000;
}