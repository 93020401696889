.paintingInfoFont {
  color: var(--bs-gray);
  font-style: italic;
}

.imagePlaceholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40rem;
  border: 3px dashed var(--bs-primary);
}

.paintingInfoGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0 0;
  text-align: left;
}

.paintingInfoGrid > div {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px solid #cdcdcd;
  word-break: break-all;
}

.paintingInfoGrid > div > svg {
  margin-right: 0.5rem;
}

@media (max-width: 544px) {
  .paintingInfoGrid {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4,1fr);
  }
}