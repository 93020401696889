.seriesDescription {
  width: 100%;
  margin-top: 0.8em;
  background-color: white;
}

.panel {
  height: 20rem;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(9, 1fr);
  gap: 0 0;
}

.panel > div {
  background-size: cover;
  transition: all 0.3s ease-in-out;
}

.thumb1Area {
  grid-area: 1 / 2 / span 6 / span 4;
}

.panel:hover .thumb1Area {
  transform: translate(0, -35px);
}

.thumb2Area {
  grid-area: 3 / 4 / span 6 / span 4;
}

.panel:hover .thumb2Area {
  transform: translate(40px, 0);
}

.thumb3Area {
  grid-area: 4 / 1 / span 6 / span 4;
}

.panel:hover .thumb3Area {
  transform: translate(-10px, 20px);
}
