.paintingCardTitle {
  background-color: var(--bs-light);
  margin-top: 0.5em;
  float: right;
  font-size: 0.9em;
  font-weight: bold;
  text-align: left;
  padding: 0.8em;
  border: 1px solid var(--bs-light);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

.paintingSelected img {
  border: 4px solid var(--bs-primary);
}

.paintingUnselectable img {
  border: 4px solid var(--bs-danger);
}
